//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
//Zona de importacion de componentes internos.
import Registro from "../Usuario/Registro";
import "./static/css/PedidoNew.css";
//Zona de importacion de Css.

import TagManager from "react-gtm-module";

import ModalEpayco from "./components/modal"

import ModalAddi from "./components/modalAddi";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const Pedido = ({
  headerState,
  modificarHeaderState,
  carrito,
  modificarCarritoState,
  pedidoState,
  modificarPedidoState,
  // clienteState,
  // modificarClienteState,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  actualizarSessionStorage,
  administradorState,
  modificarAdministradorState,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
  metodoFact,
  setMetodoFact,
}) => {
  const { clienteState, modificarClienteState } = useGlobalContext();

  //Se extraen los atributos del pedidos State.
  const {
    Pagina,
    Efecty,
    EfectyImg,
    Baloto,
    BalotoImg,
    PSE,
    PSEImg,
    Credito,
    CreditoImg,
    Contraentrega,
    ContraentregaImg,
    Consignacion,
    ConsignacionImg,
    SuperGiros,
    SuperGirosImg,
    Epayco,
    EpaycoImg,
    Addi,
    AddiImg
  } = pedidoState;

  /** State de que sincroniza el formulario con la pagina de pedido
   * Usuario: Si es true, el formulario es correcto y se procede a actualizar
   * Ejecucion: Envia señal para validar el formulario de registro
   */
  const [actualizar, setActualizar] = useState({
    Usuario: false,
    Sucursal: false,
    Ejecucion: false,
  })

  useEffect(() => {
    if(actualizar.Usuario) {
      cambioPagina(3);
    }
  }, [actualizar])
  

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  //Se definen los estilos dinamicos de cada boton de pagina.
  const stylePagina1 =
    Pagina === 1 ? { backgroundColor: "black", color: "white" } : {};
  const stylePagina2 =
    Pagina === 2 ? { backgroundColor: "black", color: "white" } : {};
  const stylePagina3 =
    Pagina === 3 ? { backgroundColor: "black", color: "white" } : {};

  //Estilos para los botones de pago.
  const EfectyBackground = Efecty ? { backgroundColor: "black" } : {};
  const BalotoBackground = Baloto ? { backgroundColor: "black" } : {};
  const PSEBackground = PSE ? { backgroundColor: "black" } : {};
  const CreditoBackground = Credito ? { backgroundColor: "black" } : {};
  const ConsignacionBackground = Consignacion
    ? { backgroundColor: "black" }
    : {};
  const ContarEntregaBackground = Contraentrega
    ? { backgroundColor: "black" }
    : {};
  const SuperGirosBackground = SuperGiros ? { backgroundColor: "black" } : {};
  const EpaycoBackground = Epayco ? { backgroundColor: "black" } : {};

  const calcularDescuento = (precio, descuento) => {
    const precioInt = parseInt(precio);
    const descuentoAplicar = parseInt(descuento);
    return precioInt - precioInt * (descuentoAplicar / 100);
  };

  //Variable sobre el total del carrito.
  const [totalCarritoState, modificarTotalCarritoState] = useState(null);
  const [costoExtraPorRecaudo, modificarcostoExtraPorRecaudo] = useState(null);

  //Retorna el total a pagar para el carrito actual.
  const calcularTotalCarrito = () => {
    let total = 0;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      total +=
        carrito["Carrito"][i]["Cantidad"] *
        (headerState["Cliente"] === "Minoristas"
          ? carrito["Carrito"][i]["DescuentoMinorista"] !== 0
            ? calcularDescuento(
                carrito["Carrito"][i]["PrecioMinorista"],
                carrito["Carrito"][i]["DescuentoMinorista"]
              )
            : carrito["Carrito"][i]["PrecioMinorista"]
          : carrito["Carrito"][i]["DescuentoMayorista"] !== 0
          ? calcularDescuento(
              carrito["Carrito"][i]["PrecioMayorista"],
              carrito["Carrito"][i]["DescuentoMayorista"]
            )
          : carrito["Carrito"][i]["PrecioMayorista"]);
    }

    return parseInt(total);
  };

  // useEffect(() => {
  //   if (paymentSuccess) {
  //     vaciarCarrito();
  //     modificarErrorState({
  //       Error: true,
  //       Mensaje:
  //         "Hemos separado tu compra, realiza el pago en las proximas 48 horas o tu pedido sera eliminado automaticamente. Recuerda enviarnos una foto del comprobante por whatsapp.",
  //       Total: "$",
  //     });
  //   }
  //   console.log("paymentError")
  //   if (paymentError) {
  //     vaciarCarrito();
  //     modificarErrorState({
  //       Error: true,
  //       Mensaje:
  //         "Error con el pago",
  //       Total: "$",
  //     });
  //   }
  // }, [paymentSuccess, paymentError])

  //Se realiza la peticion para la verificacion de un producto solicitando la cantidad de este en bodega.
  const ConsultarBodega = async (Producto, callback) => {
    let idProducto = Producto["id"];
    const codProducto = Producto.Bodega;
    // idProducto = idProducto.slice(-1) === "U"? idProducto.slice(0, -1) : idProducto
    // let posMarca = Producto.Nombre.indexOf("CROATTA");
    // let Marca = posMarca == -1? "DK" : "CROATTA";
    let rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto
    if (localStorage.getItem("Vendedor")) {
      rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto + `/${codProducto}`
    }
    await axios
      .get(
        apiUrlState + rutaVerificacion,
        {}
      )
      .then((response) => {
        if (response.data.existe) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(response.data.cantidad);
        } else {
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.Mensaje,
          });
        }
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      });
  };

  //Disminuye la cantidad de un producto en el carrito en 1 segun la talla ingresada de este.
  //Actualiza el state del carrito y tambien el sesion storage.
  const disminuirCantidad = (producto) => {
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto["id"] === carrito["Carrito"][i]["id"]) {
        if (carrito["Carrito"][i]["Cantidad"] - 1 === 0)
          carrito["Carrito"].splice(i, 1);
        else carrito["Carrito"][i]["Cantidad"] -= 1;
      }
    }
    const carritoNew = [...carrito["Carrito"]];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
  };

  //Se realiza la validacion en bodega para aumentar en 1 la cantidad en carrito de este, si no existe lo crea.
  //Actualiza el state del carrito y tambien el localStorage.
  const aumentarCantidad = async (producto, e, tipo) => {
    await ConsultarBodega(producto, function (cantidadFisica) {
      if (producto["Cantidad"] + 1 <= cantidadFisica) {
        for (var i = 0; i < carrito["Carrito"].length; i++) {
          if (producto["id"] === carrito["Carrito"][i]["id"]) {
            carrito["Carrito"][i]["Cantidad"] += 1;
          }
        }
        const carritoNew = [...carrito["Carrito"]];
        modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
        actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
      } else {
        var elemento = document.getElementById(e.target.id + "Cantidad" + tipo);
        elemento.classList.add("hint--always");
        elemento.classList.add("hint--bottom");
        elemento.classList.add("hint--bounce");
        setTimeout(function () {
          elemento.classList.remove("hint--always");
          elemento.classList.remove("hint--bottom");
          elemento.classList.remove("hint--bounce");
        }, 1000);
      }
    });
  };

  //Elimina un producto del state del carrito.
  const borrarElemento = (producto) => {
    carrito["Carrito"] = carrito["Carrito"].filter(
      (item) => item["id"] !== producto["id"]
    );
    const carritoNew = [...carrito["Carrito"]];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
  };

  //Añade el punto de miles al precio ingresado y lo retorna.
  const formatNumber = (amount) => {
    var temp = amount + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  //Segun el tipo, modifica como seleccionado y deselecciona los demas, actualiza el statePedido
  const botonPago = (tipo) => {
    let letEfecty = tipo === "Efecty" ? !Efecty : false;
    let letBaloto = tipo === "Baloto" ? !Baloto : false;
    let letPSE = tipo === "PSE" ? !PSE : false;
    let letCredito = tipo === "Credito" ? !Credito : false;
    let letContraEntrega = tipo === "ContraEntrega" ? !Contraentrega : false;
    let letEpayco = tipo === "Epayco" ? !Epayco : false;
    let letAddi = tipo === "Addi" ? true : false;

    let letEfectyImg = letEfecty
      ? recursosUrlState + "/img/Pedido/efecty-blanco.webp"
      : recursosUrlState + "/img/Pedido/efecty-negro.webp";
    let letBalotoImg = letBaloto
      ? recursosUrlState + "/img/Pedido/baloto-blanco.webp"
      : recursosUrlState + "/img/Pedido/baloto-negro.webp";
    let letPSEImg = letPSE
      ? recursosUrlState + "/img/Pedido/pse-blanco.webp"
      : recursosUrlState + "/img/Pedido/pse-negro.webp";
    let letCreditoImg = letCredito
      ? recursosUrlState + "/img/Pedido/credito-blanco.webp"
      : recursosUrlState + "/img/Pedido/credito-negro.webp";
    let letContraEntregaImg = letContraEntrega
      ? recursosUrlState + "/img/Pedido/contraentrega-blanco.webp"
      : recursosUrlState + "/img/Pedido/contraentrega-negro.webp";
    let letEpaycoImg = letEpayco
      ? recursosUrlState + "/img/Pedido/epayco-blanco.png"
      : recursosUrlState + "/img/Pedido/epayco-negro.png";    
    let letAddiImg = letAddi
    ? recursosUrlState + "/img/Pedido/AddiPago2.png"
    : recursosUrlState + "/img/Pedido/AddiPago2.png";

    modificarPedidoState({
      Pagina: Pagina,
      Efecty: letEfecty,
      EfectyImg: letEfectyImg,
      Baloto: letBaloto,
      BalotoImg: letBalotoImg,
      PSE: letPSE,
      PSEImg: letPSEImg,
      Credito: letCredito,
      CreditoImg: letCreditoImg,
      Contraentrega: letContraEntrega,
      ContraentregaImg: letContraEntregaImg,
      Consignacion: Consignacion,
      ConsignacionImg: ConsignacionImg,
      SuperGiros: SuperGiros,
      SuperGirosImg: SuperGirosImg,
      Epayco: letEpayco,
      EpaycoImg: letEpaycoImg,
      Addi: letAddi,
      AddiImg: letAddiImg
    });
  };

  //Realiza un cambio de pagina modificando el statePedido.
  const cambioPagina = async (pagina) => {
    if (pagina === 3) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Carrito",
          path: "/Pedido-Pago",
        },
      });

      await actualizarDatosUsuario(async function (respuesta) {
        if (respuesta) {
          modificarPedidoState({
            Pagina: pagina,
            Efecty: Efecty,
            EfectyImg: EfectyImg,
            Baloto: Baloto,
            BalotoImg: BalotoImg,
            PSE: PSE,
            PSEImg: PSEImg,
            Credito: Credito,
            CreditoImg: CreditoImg,
            Contraentrega: Contraentrega,
            ContraentregaImg: ContraentregaImg,
            Consignacion: Consignacion,
            ConsignacionImg: ConsignacionImg,
            SuperGiros: SuperGiros,
            SuperGirosImg: SuperGirosImg,
            Epayco: Epayco,
            EpaycoImg: EpaycoImg,
            Addi: Addi,
            AddiImg: AddiImg
          });
        }
      });

    } else if (pagina === 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Carrito",
          path: "/Pedido-Datos",
        },
      });
      modificarPedidoState({
        Pagina: pagina,
        Efecty: Efecty,
        EfectyImg: EfectyImg,
        Baloto: Baloto,
        BalotoImg: BalotoImg,
        PSE: PSE,
        PSEImg: PSEImg,
        Credito: Credito,
        CreditoImg: CreditoImg,
        Contraentrega: Contraentrega,
        ContraentregaImg: ContraentregaImg,
        Consignacion: Consignacion,
        ConsignacionImg: ConsignacionImg,
        SuperGiros: SuperGiros,
        SuperGirosImg: SuperGirosImg,
        Epayco: Epayco,
        EpaycoImg: EpaycoImg,
        Addi: Addi,
        AddiImg: AddiImg
      });
      modificarClienteState({
        ...clienteState,
        Accion: "Recuperar",
      });
    } else {
      modificarPedidoState({
        Pagina: pagina,
        Efecty: Efecty,
        EfectyImg: EfectyImg,
        Baloto: Baloto,
        BalotoImg: BalotoImg,
        PSE: PSE,
        PSEImg: PSEImg,
        Credito: Credito,
        CreditoImg: CreditoImg,
        Contraentrega: Contraentrega,
        ContraentregaImg: ContraentregaImg,
        Consignacion: Consignacion,
        ConsignacionImg: ConsignacionImg,
        SuperGiros: SuperGiros,
        SuperGirosImg: SuperGirosImg,
        Epayco: Epayco,
        EpaycoImg: EpaycoImg,
        Addi: Addi,
        AddiImg: AddiImg
      });
    }
  };

  //Revisa que ningun datos del state de cliente este vacio.
  const revisionDatosUsuarios = async() => {
    for (const dato in clienteState) {
      if (dato === "Apellidos" && clienteState.TipoDocumento === "11") {        
      } else if(dato === "FechaNacimiento" || dato === "Universidad") {        
      } else {
        if (clienteState[dato] === "") {return [false, dato]};
      }
    }
    return [true, ""];
  };

  //Segun los datos del state de cliente realiza una actualizacion de este en Contapyme.
  const actualizarDatosUsuario = async (callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Actualizando tu datos...",
      });
    }
    await axios
      .post(apiUrlState + "/Api/Usuario/ActualizarUsuarioMger", {
        NuevosDatosUsuario: [
          {
            NumeroDocumento: clienteState["NumeroDocumento"],
            TipoDocumento: clienteState["TipoDocumento"],
            Correo: clienteState["Correo"],
            Nombres: clienteState["Nombres"],
            Apellidos: clienteState["Apellidos"],
            FechaNacimiento: clienteState["FechaNacimiento"],
            Celular: clienteState["Celular"],
            Genero: clienteState["Genero"],
            Pais: clienteState["Pais"],
            Departamento: clienteState["Departamento"],
            Ciudad: clienteState["Ciudad"],
            Direccion: clienteState["Direccion"],
            Barrio: clienteState["Barrio"],
            TipoTercero: headerState["Cliente"],
            Sucursal: "0", // para actualizar el usuario
          },
        ],
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(true);
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: response.data.message,
            });
          }
          callback(false);
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({ Error: true, Mensaje: "Revisa Tus Datos." });
        }
      });
  };

  //Limpia el state de carrito de todos los productos actuales.
  const vaciarCarrito = () => {
    modificarCarritoState({ Accion: "Activo", Carrito: [] });
    actualizarSessionStorage("Carrito", JSON.stringify([]));
  };

  //Retorna la cantidad de productos en el carrito actual.
  const contarProductos = () => {
    let cantidad = 0;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      cantidad += carrito["Carrito"][i]["Cantidad"];
    }

    return cantidad;
  };

  const listaCarrito = () => {
    let listaALlevar = [];
    for (let i = 0; i < carrito["Carrito"].length; i++) {
      // if ( carrito["Carrito"][i]["id"].slice(-1) === "U") {
      if (false) {
        listaALlevar.push({
          _id: carrito["Carrito"][i]["_id"],
          Cantidad: carrito["Carrito"][i]["Cantidad"],
          Nombre: carrito["Carrito"][i]["Nombre"],
          Codigo: carrito["Carrito"][i]["Codigo"],
          Referencia: carrito["Carrito"][i]["Codigo"],
          PrecioMayorista: carrito["Carrito"][i]["PrecioMayorista"],
          PrecioMinorista: carrito["Carrito"][i]["PrecioMinorista"],
          Talla: carrito["Carrito"][i]["Talla"],
          DescuentoMayorista: carrito["Carrito"][i]["DescuentoMayorista"],
          DescuentoMinorista: carrito["Carrito"][i]["DescuentoMinorista"],
        });
      } else {
        listaALlevar.push({
          _id: carrito["Carrito"][i]["_id"],
          Cantidad: carrito["Carrito"][i]["Cantidad"],
          Nombre: carrito["Carrito"][i]["Nombre"],
          Codigo: carrito["Carrito"][i]["id"],
          Referencia: carrito["Carrito"][i]["Codigo"],
          Bodega: carrito["Carrito"][i]["Bodega"],
          PrecioMayorista: carrito["Carrito"][i]["PrecioMayorista"],
          PrecioMinorista: carrito["Carrito"][i]["PrecioMinorista"],
          Talla: carrito["Carrito"][i]["Talla"],
          DescuentoMayorista: carrito["Carrito"][i]["DescuentoMayorista"],
          DescuentoMinorista: carrito["Carrito"][i]["DescuentoMinorista"],
        });
      }
    }

    return listaALlevar;
  };

  const calcularPedido = async () => {
    await axios
      .post(apiUrlState + "/Api/Pedido/CalcularTotalPedido", {
        TipoCliente: headerState.Cliente.toUpperCase(),
        Carrito: listaCarrito(),
        Cupon: carrito.Cupon !== null ? carrito.Cupon : "",
      })
      .then((response) => {
        if (!response.data.error) {
          modificarTotalCarritoState(response.data.TotalPedido);
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        }
      })
      .catch(function (err) {
        console.log("err", err);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const crearDatosAddi = (response) => {
    let newItems = [];
    let carrito = listaCarrito();
    for(const item of carrito) {
      let producto = {
        sku: item.Referencia,
        name: item.Nombre,
        quantity: item.Cantidad,
        unitPrice: headerState.Cliente==="Minoristas"? item.PrecioMinorista : item.PrecioMayorista, // Revisar
        tax: 0,
        pictureUrl: `https://hosting.dkda.com.co/AppWeb/img/Productos/Ref%20${item.Referencia}.webp`,
        category: "Ropa",
        brand: "DK"
      }
      newItems.push(producto);
    }

    let body = {
      orderId: response.data.data.NumeroOperacion,
      totalAmount: response.data.TotalPedido,
      shippingAmount: 0, // gastos de envio
      totalTaxesAmount: 0, // valor del iva
      currency: "COP",
      items: newItems,
      client: {
        idType: "CC",
        idNumber: clienteState.NumeroDocumento,
        firstName: clienteState.Nombres,
        lastName: clienteState.Apellidos,
        email: clienteState.Correo,
        cellphone: clienteState.Celular,
        cellphoneCountryCode: "+57",
        address: {
          lineOne: clienteState.Direccion,
          city: clienteState.Ciudad,
          "country": "CO"
        }
      },
      shippingAddress: {
        lineOne: clienteState.Direccion,
        city: clienteState.Ciudad,
        "country": "CO"
      },
      billingAddress: {
        lineOne: clienteState.Direccion,
        city: clienteState.Ciudad,
        country: "CO"
      },
      pickUpAddress: {
        lineOne: "",
        city: "",
        country: "CO"
      },
      allyUrlRedirection: {
        logoUrl: "https://hosting.dkda.com.co/AppWeb/img/Email/45441614379749154.png", // https://api.dkda.com.co/Api/Marketplaces/Addi/ConfirmacionPago
        callbackUrl: "https://api.dkda.com.co/Api/Marketplaces/Addi/ConfirmacionPago",
        redirectionUrl: "https://www.dkda.com.co/"
      },
      geoLocation: {
        latitude: "",
        longitude: ""
      }
    }
    return body;
  }

  //Funcion principal:
  //Crea la remision en contapyme determinando diferentes casos.
  //Define si es remision de mayoristas o minoristas.
  //Crea, guarda y procesa la orden.
  //Crea el link de pago dependiendo de la eleccion del cliente y de si es mayorista o minorista.
  const crearPedido = async () => {
    console.log("Epayco: ", Epayco)
    if (headerState["Cliente"] === "Mayoristas" && contarProductos() < 6) {
      modificarErrorState({
        Error: true,
        Mensaje: "Tu pedido no debe tener menos de 6 unidades",
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "Carrito",
          path: "/Pedido-Final",
        },
      });
      let bancos = document.getElementById("bancos_input");
      bancos = bancos === null ? "" : bancos.value;
      if (Contraentrega) {
        verificarCiudadEnvioRR();
      } else if (
        Efecty ||
        Baloto ||
        Epayco ||
        Addi ||
        (PSE && bancos !== "A continuación seleccione su banco") ||
        headerState["Cliente"] === "Mayoristas"
      ) {
        const tipoPago = Efecty
          ? "Efecty"
          : Baloto
          ? "Baloto"
          : PSE
          ? "PSE"
          : Epayco
          ? "Epayco"
          : Addi
          ? "Addi"
          : "Mayoristas";
        if (!spinnerState["Desplegar"]) {
          modificarSpinnerState({
            Desplegar: !spinnerState["Desplegar"],
            Texto: "Creando pedido...",
          });
        }
        await axios
          .post(apiUrlState + "/Api/Manager/Crear/remision", {
            Cliente: {
              NumeroDocumento: clienteState.NumeroDocumento,
              Sucursal: clienteState.Sucursal,
              TipoCliente: headerState.Cliente,
              TipoPago: tipoPago,
            },
            Carrito: listaCarrito(),
            MedioDePago: tipoPago.toLowerCase(),
            Cupon: carrito.Cupon !== null ? carrito.Cupon : "",
            Banco: bancos,
            Vendedor: vendedor,
            Bodega: Bodega,
          })
          .then(async(response) => {
            if (!response.data.error) {
              modificarSpinnerState({
                Desplegar: false,
                Texto: spinnerState["texto"],
              });
              if (errorState["Error"]) {
                modificarErrorState({
                  Error: false,
                  Mensaje: "Todo bien, todo correcto",
                });
              }
              if (headerState["Cliente"] === "Minoristas") {
                if (Efecty) {
                  vaciarCarrito();
                  modificarErrorState({
                    Error: true,
                    Mensaje: "este es tu pin",
                    Total: response.data.TotalPedido,
                    pin: response.data.data.pin,
                    MedioPago: "Efecty",
                  });
                } else if (Baloto) {
                  vaciarCarrito();
                  modificarErrorState({
                    Error: true,
                    Mensaje: "este es tu pin",
                    Total: response.data.TotalPedido,
                    pin: response.data.data.pin,

                    MedioPago: "Baloto",
                  });
                } else if (Epayco) {
                  
                  // modificarErrorState({
                  //   Error: true,
                  //   Mensaje: "Te redireccionamos a la ventana de pago.",
                  //   Total: response.data.data.TotalPedido,
                  // });
                  //let validateTransaction = false
                  const data = {
                    key: '0026d7220b852a0f67c6ed61d2e8c9fd',
                    amount: response.data.TotalPedido,
                    name: `DK ${headerState["Cliente"] }`,
                    description: 'DK',
                    image: 'https://picsum.photos/200', // URL de la imagen del producto
                    currency: 'COP',
                    country: 'CO',
                    test: false,
                    external: true,
                    //response: 'http://localhost', // URL de la página de respuesta
                    //confirmation: 'http://localhost', // URL de la página de confirmación
                    acepted: "https://www.dkda.com.co/Pedido/Confirmacion",
                    rejected: "https://www.dkda.com.co/Pedido/Rechazado",
                    pending: "https://www.dkda.com.co/Pedido/Pendiente",
                    // onapproved: () => setPaymentSuccess(true),
                    // onrejected: () =>  setPaymentError(true)
                  };
                  modificarSpinnerState({
                    Desplegar: !spinnerState["Desplegar"],
                    Texto: "Dirigiendo a plataforma de pago...",
                  });
                      // Carga el script de Epayco y muestra el formulario de pago
                  const handler = window.ePayco.checkout.configure({
                    key: data.key,
                    test: data.test,
                  });
                  vaciarCarrito();
                  handler.open(data);

                } else if(Addi) {
                  await axios
                  .post(apiUrlState + "/Api/Marketplaces/Addi/Autenticacion", {})
                  .then(async(auth)=>{
                    let body = crearDatosAddi(response);
                    let url = await axios
                    .post(apiUrlState + "/Api/Marketplaces/Addi/CrearTransaccion", body);
                    // window.open(url.data.Respuesta, "_blank");
                    vaciarCarrito();
                    modificarErrorState({
                      Error: true,
                      Mensaje: "Te redireccionamos a la ventana de pago Addi.",
                      Total: response.data.TotalPedido,
                      Link: url.data.Respuesta,
                    });
                  })
                } else {
                  window.open(response.data.data.UrlPse, "_blank");
                  vaciarCarrito();
                  modificarErrorState({
                    Error: true,
                    Mensaje: "Te redireccionamos a la ventana de pago.",
                    Total: response.data.data.TotalPedido,
                  });
                }
              } else {
                if(Addi) {
                  await axios
                  .post(apiUrlState + "/Api/Marketplaces/Addi/Autenticacion", {})
                  .then(async(auth)=>{
                    let body = crearDatosAddi(response);
                    let url = await axios
                    .post(apiUrlState + "/Api/Marketplaces/Addi/CrearTransaccion", body);
                    // window.open(url.data.Respuesta, "_blank");
                    vaciarCarrito();
                    modificarErrorState({
                      Error: true,
                      Mensaje: "Te redireccionamos a la ventana de pago Addi.",
                      Total: response.data.TotalPedido,
                      Link: url.data.Respuesta,
                    });
                  })
                }else {
                  ReactPixel.track("Finalizar Compra", "Purchase");
                  vaciarCarrito();
                  modificarErrorState({
                    Error: true,
                    Mensaje:
                      "Hemos separado tu compra, realiza el pago en las proximas 48 horas o tu pedido sera eliminado automaticamente. Recuerda enviarnos una foto del comprobante por whatsapp.",
                    Total: "$" + response.data.TotalPedido,
                  });
                }
              }
            } else {
              modificarSpinnerState({
                Desplegar: false,
                Texto: spinnerState["texto"],
              });
              if (
                !errorState["Error"] &&
                response.data.Mensaje === "No es posible generar el pedido."
              ) {
                modificarErrorState({
                  Error: true,
                  Mensaje: response.data.Mensaje,
                  Referencias: response.data.ProductosNoPermitidos,
                });
              } else if (
                !errorState["Error"] &&
                response.data.Mensaje ===
                  "Se han agotado algunos productos de tu carrito."
              ) {
                modificarErrorState({
                  Error: true,
                  Mensaje: response.data.Mensaje,
                  Referencias: response.data.ProductosNoPermitidos,
                });
              } else if (
                !errorState["Error"] &&
                response.data.Mensaje !== undefined
              ) {
                modificarErrorState({
                  Error: true,
                  Mensaje: "Error al recuperar datos del servidor ",
                });
              }
            }
          })
          .catch(function (err) {
            modificarSpinnerState({
              Desplegar: false,
              Texto: spinnerState["texto"],
            });
            if (
              !errorState["Error"] &&
              err.response.data.Mensaje ===
                "Se han agotado algunos productos de tu carrito."
            ) {
              modificarErrorState({
                Error: true,
                Mensaje: err.response.data.Mensaje,
                Referencias: err.response.data.ProductosNoPermitidos,
              });
            } else if (
              !errorState["Error"] &&
              err.response.data.Mensaje === "No es posible generar el pedido."
            ) {
              modificarErrorState({
                Error: true,
                Mensaje: err.response.data.Mensaje,
              });
            } else if (
              !errorState["Error"] &&
              err.response.data.Mensaje !== undefined
            ) {
              modificarErrorState({
                Error: true,
                Mensaje: "Error al recuperar datos del servidor",
              });
            }
          });
      } else {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Debes seleccionar un medio de pago",
          });
        }
      }
    }
  };

  //Esta funcion comprueba si existe la imagen en version jpg del producto, en caso que no, se lanza la imagen de respaldo general 'base'.
  const comprobarImgProducto = (codigo, talla) => {
    const imagen = document.getElementById(codigo + talla + "Pedido");
    let img = document.createElement("img");
    img.src = recursosUrlState + "/img/Productos/Ref " + codigo + ".webp";
    imagen.innerHTML =
      "<img class ='img-producto-carrito' src= '" +
      recursosUrlState +
      "/img/Productos/Ref " +
      codigo +
      ".webp' alt = '" +
      codigo +
      "' loading = 'lazy' />";
    img.onerror = function () {
      imagen.innerHTML =
        "<img class ='img-producto-carrito' src= '" +
        recursosUrlState +
        "/img/General/base.png' alt = '" +
        codigo +
        "' loading = 'lazy' />";
    };
  };

  //Redireccion al Mercado.
  const volverMercado = () => {
    headerState["Cliente"] === "Mayoristas"
      ? (window.location.href = "/Mayoristas")
      : (window.location.href = "/");
  };

  const comprobarCupon = async () => {
    let valorCupon = document.getElementById("cupon_input").value;
    if (valorCupon !== "") {
      modificarSpinnerState({
        Desplegar: true,
        Texto: "Revisando tu cupon...",
      });
      await axios
        .get(apiUrlState + "/Api/Cupon/ValidarCupon/" + valorCupon)
        .then((response) => {
          if (!response.data.error && response.data.existe) {
            carrito.Cupon = valorCupon;
            calcularPedido();
            modificarSpinnerState({
              Desplegar: false,
              Texto: spinnerState["texto"],
            });
            let Nombre = "Cumpleaños - " + clienteState["Nombres"];
            clienteState["Nombres"] = Nombre;
            modificarClienteState(() => {
              return { ...clienteState, Nombres: Nombre };
            });

            modificarErrorState({
              Error: true,
              Mensaje: "Tu cupon se canjeo correctamente",
              Cliente: clienteState["Nombres"],
              Tipo: response.data.tipo,
              Descripcion: response.data.descripcion,
              valor: response.data.valor,
            });
          } else {
            modificarSpinnerState({
              Desplegar: false,
              Texto: spinnerState["texto"],
            });
            modificarErrorState({
              Error: true,
              Mensaje: "Tu cupon es invalido o ha expirado",
              Cliente: clienteState["Nombres"],
            });
          }
        })
        .catch(function (err) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        });
    } else {
      modificarSpinnerState({ Desplegar: false, Texto: spinnerState["texto"] });
      modificarErrorState({
        Error: true,
        Mensaje: "Si quieres canjear un cupon debes especificar un codigo",
      });
    }
  };

  const PagoPorConsignacion = async (tipoConsignacion) => {
    let mensaje = "Consignacion " + tipoConsignacion;

    modificarErrorState({
      Error: true,
      Mensaje: mensaje,
      Cliente: clienteState["Nombres"],
      Total: await calcularTotalCarrito(),
    });
  };

  const verificarCiudadEnvioRR = async () => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Verificando Disponibilidad",
    });
    let ciudad = clienteState["Ciudad"];
    let Departamento = clienteState["Departamento"];
    let ciudaR = await axios.post(apiUrlState + "/Api/Pedido/VerificarCiudad", [
      {
        _id: ciudad,
      },
      { Departamento: Departamento },
    ]);
    let Total = calcularTotalCarrito();
    if (Total > 2000000) {
      modificarSpinnerState({
        Desplegar: false,
        Texto: spinnerState["texto"],
      });
      modificarErrorState({
        Error: true,
        Mensaje: "Monto NO disponible para RR",
      });
    } else if (ciudaR.data[0].Nombre === "TULUA") {
      let Nombre = "Recaudo - " + clienteState["Nombres"];
      clienteState["Nombres"] = Nombre;
      modificarClienteState(() => {
        return { ...clienteState, Nombres: Nombre };
      });
      modificarSpinnerState({
        Desplegar: false,
        Texto: spinnerState["texto"],
      });
      let CostoEnvioRappi = "$2.600 pesos a $3.600 pesos";
      if (
        clienteState["Direccion"].toUpperCase().includes("AGUACLARA") ||
        clienteState["Barrio"].toUpperCase().includes("AGUACLARA")
      ) {
        CostoEnvioRappi = "$3.600 pesos a $4.600 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("RIVERA") ||
        clienteState["Barrio"].toUpperCase().includes("RIVERA")
      ) {
        CostoEnvioRappi = "$4.100 pesos a $6.000 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("COLINA") ||
        clienteState["Barrio"].toUpperCase().includes("COLINA")
      ) {
        CostoEnvioRappi = "$5.100 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("NARIÑO") ||
        clienteState["Barrio"].toUpperCase().includes("NARIÑO")
      ) {
        CostoEnvioRappi = "$3.100 pesos a $4.100 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("ESQUINAS") ||
        clienteState["Barrio"].toUpperCase().includes("ESQUINAS")
      ) {
        CostoEnvioRappi = "$5.100 pesos a $6.000 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("MARINA") ||
        clienteState["Barrio"].toUpperCase().includes("MARINA")
      ) {
        CostoEnvioRappi = "$20.000 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("TRUJILLO") ||
        clienteState["Barrio"].toUpperCase().includes("TRUJILLO")
      ) {
        CostoEnvioRappi = "$25.000 pesos";
      } else if (
        clienteState["Direccion"].toUpperCase().includes("RIOFRIO") ||
        clienteState["Barrio"].toUpperCase().includes("RIOFRIO")
      ) {
        CostoEnvioRappi = "$18.000 pesos";
      }

      modificarErrorState({
        Error: true,
        Mensaje: "Envio Rappi",
        Cliente: clienteState["Nombres"],
        EnvioRR: CostoEnvioRappi,
        Total: await calcularTotalCarrito(),
      });
    } else if (ciudaR.data[0].Recaudo) {
      let CostoExtraPorRR = 0;
      let NuevoTotal = Total;
      if (Total > 1 && Total <= 50000) {
        CostoExtraPorRR = 2500;
        NuevoTotal = CostoExtraPorRR + NuevoTotal;
      } else if (Total > 50001 && Total <= 100000) {
        CostoExtraPorRR = Total * 0.035;
        NuevoTotal = CostoExtraPorRR + NuevoTotal;
      } else if (Total > 100001 && Total <= 200000) {
        CostoExtraPorRR = Total * 0.032;
        NuevoTotal += CostoExtraPorRR;
      } else if (Total > 200001 && Total <= 500000) {
        CostoExtraPorRR = Total * 0.03;
        NuevoTotal = CostoExtraPorRR + NuevoTotal;
      } else if (Total > 500001 && Total <= 800000) {
        CostoExtraPorRR = Total * 0.027;
        NuevoTotal += CostoExtraPorRR;
      } else if (Total > 800001) {
        CostoExtraPorRR = Total * 0.024;
        NuevoTotal += CostoExtraPorRR;
      }

      let Nombre = "Recaudo - " + clienteState["Nombres"];
      clienteState["Nombres"] = Nombre;
      modificarClienteState(() => {
        return { ...clienteState, Nombres: Nombre };
      });
      //modificarcostoExtraPorRecaudo(CostoExtraPorRR);
      //modificarTotalCarritoState(NuevoTotal);

      modificarSpinnerState({
        Desplegar: false,
        Texto: spinnerState["texto"],
      });
      modificarErrorState({
        Error: true,
        Mensaje: "Ciudad Disponible para RR",
        Cliente: clienteState["Nombres"],
        EnvioRR: CostoExtraPorRR,
        Total: NuevoTotal,
      });
    } else {
      modificarSpinnerState({
        Desplegar: false,
        Texto: spinnerState["texto"],
      });
      modificarErrorState({
        Error: true,
        Mensaje: "Ciudad NO disponible para RR",
      });
    }
  };

  //Se ejecuta al inciar el componente.
  //Pregunta si la barra de busqueda esta desplegada, si es asi actualiza el componente del header para no desplegarla.
  //Si el campo de registro esta activado este se desactiva, actualizando tambien el localStorage.
  useEffect(() => {
    var header = JSON.parse(JSON.stringify(headerState));
    if (header["displayBuscar"] !== "none") {
      header["displayBuscar"] = "none";
      header["displayFlecha"] = "none";
      modificarHeaderState(header);
    }
    if (clienteState["Registro"]) {
      localStorage.setItem(
        "Cliente",
        JSON.stringify({
          ...clienteState,
          Registro: false,
        })
      );
      modificarClienteState({
        ...clienteState,
        Registro: false,
      });
    }
    if (totalCarritoState === "0" || totalCarritoState === null) {
      if (carrito.Cupon !== null) {
        modificarTotalCarritoState(calcularPedido());
      } else {
        modificarTotalCarritoState(calcularTotalCarrito());
      }
    }
  }, []);

  //Esta funcion reemplaza el 'webp' por 'png' en un string
  const cambioPng = (url) => {
    return url.replace(/webp/g, "png");
  };

  const mostarPolitica = () => {
    modificarErrorState({ Error: true, Mensaje: "Politica de datos" });
  };

  const PedidoEpayco =  async () => {
    console.log("Entra a contra entrega")
    if (headerState["Cliente"] === "Mayoristas" && contarProductos() < 6) {
      modificarErrorState({
        Error: true,
        Mensaje: "Tu pedido no debe tener menos de 6 unidades",
      });
    } else {
      await axios
      .post(apiUrlState + "/Api/Manager/Crear/remision", {
        Cliente: {
          NumeroDocumento: clienteState.NumeroDocumento,
          Sucursal: clienteState.Sucursal,
          TipoCliente: headerState.Cliente,
          TipoPago: " ",
        },
        Carrito: listaCarrito(),
        MedioDePago: "epayco",
        Cupon: carrito.Cupon !== null ? carrito.Cupon : "",
        Banco: "bancos",
        Vendedor: vendedor,
        Bodega: Bodega,
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          if (headerState["Cliente"] === "Minoristas") {
            if (Epayco) {
              
              // modificarErrorState({
              //   Error: true,
              //   Mensaje: "Te redireccionamos a la ventana de pago.",
              //   Total: response.data.data.TotalPedido,
              // });
              //let validateTransaction = false
              const data = {
                key: '0026d7220b852a0f67c6ed61d2e8c9fd',
                amount: response.data.TotalPedido,
                name: `DK ${headerState["Cliente"] }`,
                description: 'DK',
                image: 'https://picsum.photos/200', // URL de la imagen del producto
                currency: 'COP',
                country: 'CO',
                test: false,
                external: true,
                //response: 'http://localhost', // URL de la página de respuesta
                //confirmation: 'http://localhost', // URL de la página de confirmación
                acepted: "https://www.dkda.com.co/Pedido/Confirmacion",
                rejected: "https://www.dkda.com.co/Pedido/Rechazado",
                pending: "https://www.dkda.com.co/Pedido/Pendiente",
                // onapproved: () => setPaymentSuccess(true),
                // onrejected: () =>  setPaymentError(true)
              };
              modificarSpinnerState({
                Desplegar: !spinnerState["Desplegar"],
                Texto: "Dirigiendo a plataforma de pago...",
              });
                  // Carga el script de Epayco y muestra el formulario de pago
              const handler = window.ePayco.checkout.configure({
                key: data.key,
                test: data.test,
              });
              vaciarCarrito();
              handler.open(data);
  
            } else {
              window.open(response.data.data.UrlPse, "_blank");
              vaciarCarrito();
              modificarErrorState({
                Error: true,
                Mensaje: "Te redireccionamos a la ventana de pago.",
                Total: response.data.data.TotalPedido,
              });
            }
          } else {
            ReactPixel.track("Finalizar Compra", "Purchase");
            vaciarCarrito();
            modificarErrorState({
              Error: true,
              Mensaje:
                "Hemos separado tu compra, realiza el pago en las proximas 48 horas o tu pedido sera eliminado automaticamente. Recuerda enviarnos una foto del comprobante por whatsapp.",
              Total: "$" + response.data.TotalPedido,
            });
          }
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (
            !errorState["Error"] &&
            response.data.Mensaje === "No es posible generar el pedido."
          ) {
            modificarErrorState({
              Error: true,
              Mensaje: response.data.Mensaje,
              Referencias: response.data.ProductosNoPermitidos,
            });
          } else if (
            !errorState["Error"] &&
            response.data.Mensaje ===
              "Se han agotado algunos productos de tu carrito."
          ) {
            modificarErrorState({
              Error: true,
              Mensaje: response.data.Mensaje,
              Referencias: response.data.ProductosNoPermitidos,
            });
          } else if (
            !errorState["Error"] &&
            response.data.Mensaje !== undefined
          ) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor ",
            });
          }
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (
          !errorState["Error"] &&
          err.response.data.Mensaje ===
            "Se han agotado algunos productos de tu carrito."
        ) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.Mensaje,
            Referencias: err.response.data.ProductosNoPermitidos,
          });
        } else if (
          !errorState["Error"] &&
          err.response.data.Mensaje === "No es posible generar el pedido."
        ) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.Mensaje,
          });
        } else if (
          !errorState["Error"] &&
          err.response.data.Mensaje !== undefined
        ) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
    }

  };

  //Propiedad Css para la flecha izq.
  const flechaIzquierdaPedido = {
    backgroundImage:
      "url(" +
      recursosUrlState +
      "/img/Index/web/botones/flecha-izquierda-negro.webp)",
  };

  /** Actualiza el state de facturación segun lo requiera el vendedor */
  const modificarFacturacion = (mod) => {
    if (mod == "add" && metodoFact.Facturacion < 100) {
      setMetodoFact({
        Facturacion: metodoFact.Facturacion += 10,
        Remision: metodoFact.Remision -= 10,
      })
    } else if (mod == "sub" && metodoFact.Facturacion > 0) {
      setMetodoFact({
        Facturacion: metodoFact.Facturacion -= 10,
        Remision: metodoFact.Remision += 10,
      })
    }
  }

  return (
    <div className="Pedido">
      <ModalEpayco accion={crearPedido}/>
      <ModalAddi accion={crearPedido} Mensaje={calcularTotalCarrito()}/>
      <div id="paginas">
        <p
          className="botonPagina"
          style={stylePagina1}
          onClick={() => cambioPagina(1)}
        >
          1
        </p>
        <p className="infoPaginas" onClick={() => cambioPagina(1)}>
          Resumen de compra
        </p>
        <p
          className="botonPagina"
          style={stylePagina2}
          onClick={() => cambioPagina(2)}
        >
          2
        </p>
        <p className="infoPaginas" onClick={() => cambioPagina(2)}>
          Datos de envío
        </p>
        <p
          className="botonPagina"
          style={stylePagina3}
          onClick={() => setActualizar({...actualizar, Ejecucion: true})}
        >
          3
        </p>
        <p className="infoPaginas" onClick={() => setActualizar({...actualizar, Ejecucion: true})}>
          Forma de pago
        </p>
      </div>
      <div style={{ width: "95%" }}>
        {Pagina === 1 ? (
          <div className="Paso1">
            <p
              onClick={() => volverMercado()}
              style={{ alignSelf: "flex-start", width: "100%" }}
            >
              <button
                className="SinDecoracionPedido flechaDerechaPedido"
                style={flechaIzquierdaPedido}
              ></button>
              <span
                className="FuenteBebas titulosPaso2"
                style={{ color: "#000000" }}
              >
                Volver al Mercado
              </span>
            </p>
            <p className="FuenteBebas titulosPaso2">RESUMEN DE TU PEDIDO</p>
            <div style={{ width: "100%" }}>
              <table className="table" style={{ marginBottom: "5rem" }}>
                <thead id="titulosWeb">
                  <tr>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Producto
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Nombre
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Precio
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Talla
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Cantidad
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    ></th>
                  </tr>
                </thead>
                <thead id="titulosMovil">
                  <tr>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Producto
                    </th>
                    <th
                      scope="col"
                      className="text-center FuenteBebas tituloTabla"
                    >
                      Informacion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {carrito["Carrito"].map((producto) => (
                    <tr
                      id="datosWeb"
                      key={producto["Codigo"] + producto["Talla"] + "Web"}
                    >
                      <td>
                        <div
                          id={producto["Codigo"] + producto["Talla"] + "Pedido"}
                          className="centrar"
                        >
                          <img
                            className="img-producto-pedido"
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref " +
                              producto["Codigo"] +
                              ".webp"
                            }
                            alt={producto["Codigo"]}
                            loading="lazy"
                            onError={(e) =>
                              comprobarImgProducto(
                                producto["Codigo"],
                                producto["Talla"]
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <p className="text-center atributosTabla">
                          {producto["Nombre"]}
                        </p>
                      </td>
                      <td>
                        {headerState["Cliente"] === "Minoristas" ? (
                          producto.DescuentoMinorista !== 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <h4
                                className="text-left atributosTabla"
                                style={{ marginRight: "10px" }}
                              >
                                <strong>
                                  ${" "}
                                  {formatNumber(
                                    calcularDescuento(
                                      producto.PrecioMinorista,
                                      producto.DescuentoMinorista
                                    )
                                  )}
                                </strong>
                              </h4>
                              <h5
                                className="text-left atributosTabla"
                                style={{
                                  textDecoration: "line-through",
                                  color: "red",
                                }}
                              >
                                <strong>
                                  {formatNumber(producto.PrecioMinorista)}
                                </strong>
                              </h5>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <h4
                                className="atributosTabla"
                                style={{ marginRight: "10px" }}
                              >
                                <strong>
                                  $ {formatNumber(producto.PrecioMinorista)}{" "}
                                </strong>
                              </h4>
                            </div>
                          )
                        ) : producto.DescuentoMayorista !== 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <h4
                              className="atributosTabla"
                              style={{ marginRight: "10px" }}
                            >
                              <strong>
                                ${" "}
                                {formatNumber(
                                  calcularDescuento(
                                    producto.PrecioMayorista,
                                    producto.DescuentoMayorista
                                  )
                                )}
                              </strong>
                            </h4>
                            <h5
                              className="atributosTabla"
                              style={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              <strong>
                                {formatNumber(producto.PrecioMayorista)}
                              </strong>
                            </h5>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <h4
                              className="atributosTabla"
                              style={{ marginRight: "10px" }}
                            >
                              <strong>
                                $ {formatNumber(producto.PrecioMayorista)}
                              </strong>
                            </h4>
                          </div>
                        )}
                      </td>
                      <td>
                        <p className="atributosTabla text-center">
                          {producto["Talla"]}
                        </p>
                      </td>
                      <td>
                        <div className="atributosTabla text-center">
                          <div
                            className="cantidadPedido"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            <p
                              className="SinDecoracionPedido"
                              style={{
                                fontSize: 25,
                                cursor: "pointer",
                              }}
                              onClick={() => disminuirCantidad(producto)}
                            >
                              -
                            </p>
                            <p
                              id={
                                producto["Codigo"] +
                                producto["Talla"] +
                                "CantidadWeb"
                              }
                              className="FuenteBebas"
                              data-hint="Cantidad Maxima"
                            >
                              {producto["Cantidad"]}
                            </p>
                            <p
                              className="SinDecoracionPedido"
                              style={{
                                fontSize: 20,
                                cursor: "pointer",
                              }}
                              id={producto["Codigo"] + producto["Talla"]}
                              onClick={(e) =>
                                aumentarCantidad(producto, e, "Web")
                              }
                            >
                              +
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="atributosTabla text-center">
                          {"$" +
                            formatNumber(
                              producto["Cantidad"] *
                                (headerState["Cliente"] === "Minoristas"
                                  ? producto.DescuentoMinorista !== 0
                                    ? calcularDescuento(
                                        producto["PrecioMinorista"],
                                        producto.DescuentoMinorista
                                      )
                                    : producto["PrecioMinorista"]
                                  : producto.DescuentoMayorista !== 0
                                  ? calcularDescuento(
                                      producto["PrecioMayorista"],
                                      producto.DescuentoMayorista
                                    )
                                  : producto["PrecioMayorista"])
                            )}
                        </p>
                      </td>
                      <td>
                        <img
                          className="SinDecoracionPedido botonBorrarPedido atributosTabla"
                          src={
                            recursosUrlState +
                            "/img/Header/botones/papelera-negro.webp"
                          }
                          alt="papelera"
                          loading="lazy"
                          onClick={() => borrarElemento(producto)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              recursosUrlState +
                              "/img/Header/botones/papelera-negro.png";
                          }}
                          style={{ marginTop: 45 }}
                        />
                      </td>
                    </tr>
                  ))}
                  {carrito["Carrito"].map((producto) => (
                    <tr
                      id="datosMovil"
                      key={producto["Codigo"] + producto["Talla"] + "Movil"}
                    >
                      <td>
                        <img
                          className="img-producto-pedido centrar"
                          src={
                            recursosUrlState +
                            "/img/Productos/Ref " +
                            producto["Codigo"] +
                            ".webp"
                          }
                          alt={producto["Codigo"]}
                          loading="lazy"
                          onError={(e) =>
                            comprobarImgProducto(
                              producto["Codigo"],
                              producto["Talla"]
                            )
                          }
                        ></img>
                      </td>
                      <td>
                        <p className="text-left atributosTabla">
                          {producto["Nombre"]}
                        </p>
                        <p className="text-left atributosTabla">
                          Talla: {producto["Talla"]}
                        </p>
                        {headerState["Cliente"] === "Minoristas" ? (
                          producto.DescuentoMinorista !== 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <h4
                                className="atributosTabla"
                                style={{ marginRight: "10px" }}
                              >
                                <strong>
                                  ${" "}
                                  {calcularDescuento(
                                    producto.PrecioMinorista,
                                    producto.DescuentoMinorista
                                  )}
                                </strong>
                              </h4>
                              <h5
                                className="atributosTabla"
                                style={{
                                  textDecoration: "line-through",
                                  color: "red",
                                }}
                              >
                                <strong>{producto.PrecioMinorista}</strong>
                              </h5>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <h4
                                className="atributosTabla"
                                style={{ marginRight: "10px" }}
                              >
                                <strong>$ {producto.PrecioMinorista} </strong>
                              </h4>
                            </div>
                          )
                        ) : producto.DescuentoMayorista !== 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <h4
                              className="atributosTabla"
                              style={{ marginRight: "10px" }}
                            >
                              <strong>
                                ${" "}
                                {calcularDescuento(
                                  producto.PrecioMayorista,
                                  producto.DescuentoMayorista
                                )}
                              </strong>
                            </h4>
                            <h5
                              className="atributosTabla"
                              style={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              <strong>{producto.PrecioMayorista}</strong>
                            </h5>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <h4
                              className="atributosTabla"
                              style={{ marginRight: "10px" }}
                            >
                              <strong>$ {producto.PrecioMayorista}</strong>
                            </h4>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-9">
                            <div
                              className="row text-center"
                              style={{
                                marginBottom: "0px",
                                marginTop: "5px",
                                marginLeft: "0px",
                                width: "70px",
                                fontWeight: "bold",
                              }}
                            >
                              <p
                                className="SinDecoracionPedido"
                                style={{
                                  fontSize: 25,
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                  marginTop: "-3px",
                                  marginBottom: "-6px",
                                }}
                                onClick={() => disminuirCantidad(producto)}
                              >
                                -
                              </p>
                              <p
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                  marginTop: "5px",
                                  marginBottom: "-6px",
                                }}
                                id={
                                  producto["Codigo"] +
                                  producto["Talla"] +
                                  "CantidadMovil"
                                }
                                data-hint="Cantidad Maxima"
                              >
                                {producto["Cantidad"]}
                              </p>
                              <p
                                className="SinDecoracionPedido"
                                style={{
                                  fontSize: 20,
                                  paddingLeft: "5px",
                                  marginBottom: "-6px",
                                }}
                                id={producto["Codigo"] + producto["Talla"]}
                                onClick={(e) =>
                                  aumentarCantidad(producto, e, "Movil")
                                }
                              >
                                +
                              </p>
                              <hr className="LineaTotal" />
                            </div>
                          </div>
                          <div className="col-3">
                            <img
                              className="SinDecoracionPedido botonBorrarPedido atributosTabla"
                              src={
                                recursosUrlState +
                                "/img/Header/botones/papelera-negro.webp"
                              }
                              alt="papelera"
                              loading="lazy"
                              onClick={() => borrarElemento(producto)}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  recursosUrlState +
                                  "/img/Header/botones/papelera-negro.png";
                              }}
                              style={{ marginTop: 0 }}
                            ></img>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : Pagina === 2 ? (
          <div className="Paso1">
            {/* <p
              onClick={() => cambioPagina(1)}
              style={{ alignSelf: "flex-start", width: "100%" }}
            >
              <button
                className="SinDecoracionPedido flechaDerechaPedido"
                style={flechaIzquierdaPedido}
              ></button>
              <span
                className="FuenteBebas titulosPaso2"
                style={{ color: "#000000" }}
              >
                Volver paso 1
              </span>
            </p>  */}
            <p className="FuenteBebas titulosPaso2">DATOS PARA TU ENVIO</p>
            <a
              className="FuenteHelvetica textoPaso2"
              onClick={() => mostarPolitica()}
            >
              Recuerda que, al diligenciar el formulario, estas
              <strong>
                {" "}
                <b>autorizando el tratamiento de tus datos personales.</b>
              </strong>
            </a>
            <p
              className="FuenteBebas titulosPaso2"
              style={{ marginLeft: "30px" }}
            >
              Información personal
            </p>
            <Registro
              headerState={headerState}
              modificarHeaderState={modificarHeaderState}
              pedidoState={pedidoState}
              modificarPedidoState={modificarPedidoState}
              // clienteState={clienteState}
              // modificarClienteState={modificarClienteState}
              errorState={errorState}
              modificarErrorState={modificarErrorState}
              spinnerState={spinnerState}
              modificarSpinnerState={modificarSpinnerState}
              apiUrlState={apiUrlState}
              modificarApiUrlState={modificarApiUrlState}
              recursosUrlState={recursosUrlState}
              modificarRecursosUrlState={modificarRecursosUrlState}
              actualizarSessionStorage={actualizarSessionStorage}
              actualizar={actualizar}
              setActualizar={setActualizar}
              vendedor={vendedor}
            />
          </div>
        ) : Pagina === 3 ? (
          <div className="Paso1">
            {/* <p
              onClick={() => cambioPagina(2)}
              style={{ alignSelf: "flex-start", width: "100%" }}
            >
              <button
                className="SinDecoracionPedido flechaDerechaPedido"
                style={flechaIzquierdaPedido}
              ></button>
              <span
                className="FuenteBebas titulosPaso2"
                style={{ color: "#000000" }}
              >
                Volver paso 2
              </span>
            </p>
            <p className="FuenteBebas titulosPaso2">
              VAMOS A FINALIZAR LA COMPRA
            </p> */}
            {/* <p
              className="FuenteBebas titulosPaso2"
              style={{ marginLeft: "30px" }}
            >
              Total Pedido: ${formatNumber(totalCarritoState)}
            </p> */}

            {headerState["Cliente"] === "Minoristas" ? (
              <div style={{ marginBottom: "5rem" }}>
                <p
                  className="FuenteHelvetica textoPaso2"
                  style={{ textAlign: "justify" }}
                >
                  El código para pago se mostrará al finalizar la compra. Ten en
                  cuenta que el plazo máximo para realizar el pago en cualquier
                  punto Efecty o Baloto es de 48 horas, pasado este plazo tu
                  pedido será cancelado automáticamente.
                </p>
                <div className="form-info">
                  <p className="FuenteHelvetica textoPaso2">
                    Si tienes un cupón aplicalo aqui:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Ingrese su cupón"
                      name="Cupon"
                      id="cupon_input"
                      required
                    ></input>
                    <p
                      className="botonCupon text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => comprobarCupon()}
                    >
                      Canjear
                    </p>
                  </div>
                </div>
                <p className="FuenteBebas titulosPaso2">Metódo de Pago</p>

                <p className="FuenteHelvetica textoPaso2">
                  Pagos en efectivo disponibles:{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <img
                    className="imgPago"
                    src={EfectyImg}
                    style={EfectyBackground}
                    onClick={() => botonPago("Efecty")}
                    alt="Efecty"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(EfectyImg);
                    }}
                  /> */}
                  {/* <img
                    className="imgPago"
                    src={BalotoImg}
                    style={BalotoBackground}
                    onClick={() => botonPago("Baloto")}
                    alt="Baloto"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(BalotoImg);
                    }}
                  /> */}
                  <img
                    className="imgPago"
                    src={ContraentregaImg}
                    style={ContarEntregaBackground}
                    onClick={() => verificarCiudadEnvioRR() /*botonPago("ContraEntrega")*/}
                    alt="Contraentrega"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(ContraentregaImg);
                    }}
                  />
                  {/* <img
                    className="imgPago"
                    src={EpaycoImg}
                    style={EpaycoBackground}
                    onClick={() => botonPago("Epayco")}
                    alt="Epayco"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(ContraentregaImg);
                    }}
                  /> */}
                </div>
                {/* <p className="FuenteHelvetica textoPaso2">
                  Pagos bancarios disponibles:{" "}
                </p> */}
                <p className="FuenteHelvetica textoPaso2">
                  Otros Medios de pago
                </p>
                <div>
                  <img
                    className="imgPago"
                    data-toggle="modal"
                    data-target="#exampleModalAddi"
                    src={recursosUrlState + "/img/Pedido/AddiPago2.png"}
                    onClick={() => {
                      botonPago("Addi")
                    }}
                    alt="ADDI"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(AddiImg);
                    }}
                  />
                  <p
                      className="FuenteHelvetica textoPaso2"
                      style={{ marginLeft: "0px", marginTop: "0px" }}
                    >
                      Paga a 3 cuotas sin intereses con ADDI.
                    </p>
                </div>
                <div style={{marginTop: "16px"}}>
                  <img
                    className="imgPago"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    src={EpaycoImg}
                    style={EpaycoBackground}
                    onClick={() => botonPago("Epayco")}
                    alt="Epayco"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(ContraentregaImg);
                    }}
                  />
                  <img
                    src={recursosUrlState + "/img/Pedido/Bancos.jpg"}
                    onClick={() => botonPago("Epayco")}
                    alt="Epayco"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(ContraentregaImg);
                    }}
                  />
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <img
                    className="imgPagoPSE"
                    src={PSEImg}
                    style={PSEBackground}
                    onClick={() => botonPago("PSE")}
                    alt="PSE"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = cambioPng(PSEImg);
                    }}
                  ></img>
                  {PSE ? (
                    <div className="bancos">
                      <label htmlFor="bancos">(*) Bancos</label>
                      <select
                        className="fontSelect"
                        placeholder="bancos"
                        name="Bancos"
                        id="bancos_input"
                        required
                      >
                        <option hidden defaultValue>
                          A continuación seleccione su banco
                        </option>
                        <option value="1059">BANCAMIA S.A.</option>
                        <option value="1040">BANCO AGRARIO</option>
                        <option value="1052">BANCO AV VILLAS</option>
                        <option value="1013">BANCO BBVA COLOMBIA S.A.</option>
                        <option value="1032">BANCO CAJA SOCIAL</option>
                        <option value="1066">
                          BANCO COOPERATIVO COOPCENTRAL
                        </option>
                        <option value="1558">BANCO CREDIFINANCIERA</option>
                        <option value="1051">BANCO DAVIVIENDA</option>
                        <option value="1001">BANCO DE BOGOTA</option>
                        <option value="1023">BANCO DE OCCIDENTE</option>
                        <option value="1062">BANCO FALABELLA </option>
                        <option value="1012">BANCO GNB SUDAMERIS</option>
                        <option value="1006">BANCO ITAU</option>
                        <option value="1060">BANCO PICHINCHA S.A.</option>
                        <option value="1002">BANCO POPULAR</option>
                        <option value="1065">BANCO SANTANDER COLOMBIA</option>
                        <option value="1069">BANCO SERFINANZA</option>
                        <option value="1007">BANCOLOMBIA</option>
                        <option value="1061">BANCOOMEVA S.A.</option>
                        <option value="1283">CFA COOPERATIVA FINANCIERA</option>
                        <option value="1009">CITIBANK </option>
                        <option value="1370">COLTEFINANCIERA</option>
                        <option value="1292">
                          CONFIAR COOPERATIVA FINANCIERA
                        </option>
                        <option value="1289">COTRAFA</option>
                        <option value="1551">DAVIPLATA</option>
                        <option value="1507">NEQUI</option>
                        <option value="1151">RAPPIPAY</option>
                        <option value="1019">SCOTIABANK COLPATRIA</option>
                      </select>
                    </div>
                  ) : (
                    <></>
                  )}
                </div> */}
              </div>
            ) : (
              <div style={{ marginBottom: "5rem" }}>
                <p className="FuenteHelvetica textoPaso2">
                  Al finalizar tu compra separaremos tu pedido, por favor lee
                  más abajo para obtener la información de pago.
                </p>
                {vendedor? (
                  <div>
                    <p className="FuenteBebas titulosPaso2">Selecciona porcentajes de facturación:</p>
                    <div style={{marginBottom:"40px", display:"flex", justifyContent: "space-evenly", flexWrap: "wrap"}}>
                      <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                        <label htmlFor="facturacion">% Factura</label>
                        <div style={{display:"flex"}}>
                          <button className="btn-add" style={{ marginLeft: "3px" }}
                            onClick={()=>{modificarFacturacion("sub")}} >-</button>
                          <input id="facturacion" 
                            type="number" 
                            step={10} min={0} max={100} 
                            value={metodoFact.Facturacion}
                            disabled
                          ></input>
                          <button className="btn-add"
                            onClick={()=>{modificarFacturacion("add")}} >+</button>
                        </div>
                      </div>
                      <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                        <label htmlFor="remision">% Remisión</label>
                        <div style={{display:"flex"}}>
                          <button className="btn-add"
                              onClick={()=>{modificarFacturacion("add")}} >-</button>
                          <input id="remision" 
                            type="number"
                            value={metodoFact.Remision}
                            disabled
                          ></input>
                          <button className="btn-add"
                            onClick={()=>{modificarFacturacion("sub")}} >+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <p className="FuenteBebas titulosPaso2">Realiza Tu pago por:</p>
                <div className="row align-items-start">
                  <div className="col-12 col-sm-4" align="center">
                    <div style={{ marginBottom: "50px" }}>
                      <img
                        className="imgPagoCredito"
                        src={ConsignacionImg}
                        style={ConsignacionBackground}
                        onClick={() => PagoPorConsignacion("Bancolombia")}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = cambioPng(ConsignacionImg);
                        }}
                      ></img>
                      <p
                        className="FuenteHelvetica textoPaso2"
                        style={{ marginLeft: "30px", marginTop: "15px" }}
                      >
                        Trasferencia electrónica o consignación por PAC /
                        corresponsal Bancario
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4" align="center" style={vendedor? {display:"none"} : {}}>
                    <img
                      className="imgPagoCredito"
                      src={EfectyImg}
                      style={EfectyBackground}
                      onClick={() => PagoPorConsignacion("Efecty")}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = cambioPng(EfectyImg);
                      }}
                    />
                    <p
                      className="FuenteHelvetica textoPaso2"
                      style={{ marginLeft: "30px", marginTop: "15px" }}
                    >
                      Consignación via Efecty
                    </p>
                  </div>

                  <div className="col-12 col-sm-4" align="center" style={vendedor? {display:"none"} : {}}>
                    <img
                      className="imgPagoCredito"
                      src={SuperGirosImg}
                      style={SuperGirosBackground}
                      onClick={() => PagoPorConsignacion("SuperGiros")}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = cambioPng(SuperGirosImg);
                      }}
                    />
                    <p
                      className="FuenteHelvetica textoPaso2"
                      style={{ marginLeft: "30px", marginTop: "15px" }}
                    >
                      Consignación via SuperGiros
                    </p>
                  </div>
                  <div className="col-12 col-sm-4" align="center" style={vendedor? {display:"none"} : {}}>
                    <img
                      className="imgPagoCredito"
                      src={ContraentregaImg}
                      style={ContarEntregaBackground}
                      onClick={() => verificarCiudadEnvioRR()}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = cambioPng(ContraentregaImg);
                      }}
                    />
                    <p
                      className="FuenteHelvetica textoPaso2"
                      style={{ marginLeft: "30px", marginTop: "15px" }}
                    >
                      Envío con Pago Contraentrega
                    </p>
                  </div>
                  <div className="col-12 col-sm-4" align="center" style={vendedor? {display:"none"} : {}}>
                    <img
                      className="imgPagoCredito"
                      data-toggle="modal"
                      data-target="#exampleModalAddi"
                      src={recursosUrlState + "/img/Pedido/AddiPago2.png"}
                      onClick={() => {
                        botonPago("Addi")
                      }}
                      alt="ADDI"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = cambioPng(AddiImg);
                      }}
                    />
                    <p
                      className="FuenteHelvetica textoPaso2"
                      style={{ marginLeft: "30px", marginTop: "15px" }}
                    >
                      Paga a 3 cuotas sin intereses con ADDI.
                    </p>
                </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {Pagina === 1 ? (
        <div className="BarraTotal">
          <div className="totalPedido">
            <p className="FuenteBebas" style={{ margin: "2px" }}>
              Total:
            </p>
            <p className="FuenteBebas" style={{ margin: "2px" }}>
              ${formatNumber(calcularTotalCarrito())}
            </p>
          </div>
          <div className="">
            <p
              className="botonPaso1 text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cambioPagina(2)}
            >
              Continuar compra
            </p>
          </div>
        </div>
      ) : Pagina === 2 ? (
        <div className="BarraTotal">
          <div className="totalPedido">
            <p className="FuenteBebas" style={{ margin: "2px" }}>
              Total:
            </p>
            <p className="FuenteBebas" style={{ margin: "2px" }}>
              ${formatNumber(calcularTotalCarrito())}
            </p>
          </div>
          <div className="">
            <p
              className="botonPaso2 text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => setActualizar({...actualizar, Ejecucion: true})}
            >
              Continuar compra
            </p>
          </div>
        </div>
      ) : Pagina === 3 ? (
        headerState["Cliente"] === "Minoristas" ? (
          <div className="BarraTotal">
            <div className="totalPedido">
              <p className="FuenteBebas" style={{ margin: "2px" }}>
                Total:
              </p>
              <p className="FuenteBebas" style={{ margin: "2px" }}>
                ${formatNumber(calcularTotalCarrito())}
              </p>
            </div>

            <div className="">
              <p
                className="botonPaso2 text-center FuenteBebas"
                style={{ cursor: "pointer", display: "none" }}
                onClick={() => crearPedido()}
                
              >
                finalizar compra
              </p>
            </div>
          </div>
        ) : (
          <div className="BarraTotal" style={{ justifyContent: "center" }}>
            <div className="totalPedido" style={{ fontSize: "25px" }}>
              <p className="FuenteBebas" style={{ margin: "2px" }}>
                Total:
              </p>
              <p className="FuenteBebas" style={{ margin: "2px" }}>
                ${formatNumber(calcularTotalCarrito())}
              </p>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Pedido;
