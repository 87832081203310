import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import EpaycoButton from "../Epayco";

import "./static/css/newIndex.css";
import CarouselInfo from "./CarouselInfo";
import { useCategContext } from "../../providers/CategoriasProviders";

const NewIndex = ({
  headerState,
  modificarHeaderState,
  categoriasState,
  marcasState,
  modificarCategoriasState,
  modificarMarcasState,
  productosState,
  modificarProductosState,
  spinnerState,
  modificarSpinnerState,
  errorState,
  modificarErrorState,
  apiUrlState,
}) => {

  const categorias = useCategContext();
  const recursosUrlState = "https://hosting.dkda.com.co/AppWeb";
  const images = {
    img1: [
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL1.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL12.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL13.webp`,
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL1.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL2.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL3.webp",
    ],
    img2: [
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL21.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL22.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL23.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL24.webp`,
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL21.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL22.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL23.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL24.webp",
    ],
    img3: [
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL31.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL32.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL33.webp`,
      `${recursosUrlState}/img/Index/web/home/BANNERPRINCIPAL34.webp`,
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL31.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL32.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL33.webp",
      // "https://hosting.dkda.com.co/AppWeb/img/Index/web/home/BANNERPRINCIPAL34.webp",
    ]

  }
    

  const aleatorio= (max)=>{
    return Math.floor(Math.random() * max);
  }
  const CambioProductos = async (
    categoria,
    subcategoria,
    marca,
    genero,
    nuevo = false,
    descuento = false
  ) => {
    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    let url = apiUrlState + "/Api/Inventario/GetProductos";
    let data = {};

    if (nuevo) {
      data = {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: true,
        Descuentos: false,
        Todo: false,
        Origen: "New1"
      };
    } else if (descuento) {
      data = {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: true,
        Todo: false,
        Origen: "New2"
      };
    } else {
      data = {
        Categorias: categoria === null ? [] : [categoria],
        Generos: genero === null ? [] : [genero],
        Marcas: marca === null ? [] : marca,
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: subcategoria === null ? [] : [subcategoria],
        ParametroOrdenamiento: "Marca",
        Descuentos: false,
        ProductosNuevos: false,
        Origen: "New3"
      };
    }

    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }
    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
      })
      .catch(function (err) {
        if (!errorState["Error"])
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      });
  };

  useEffect(() => {
    modificarHeaderState({ ...headerState, Cliente: "Minoristas" });
  }, []);


  // Cambio temporal para el concurso de diseño
  // const [fondo, setFondo] = useState(aleatorio(fondos.length)); // descomentar
  const [fondo, setFondo] = useState(3); // comentar


  useEffect(() => {    
    console.log("asd", fondo);
  }, []);

  return (
    <>
      <CarouselInfo index={true} images={images}/>
      <a
        href="/Mercado/Nuevo/New"
        onClick={() => CambioProductos(null, null, null, null, true, false)}
      >
        {/* <h2
          id="Boton"
          className="FuenteBebas centradoBoton"
          style={{
            textAlign: "center",
            backgroundColor: "transparent",
            textShadow:
              "0.1em 0.1em 0.1em black, 0.1em 0.1em 0.2em black, 0.1em 0.1em 0em black",
          }}
        >
          NEW
        </h2> */}
        <h2 style={{ color:"black", fontFamily: "Arial_Bold", textAlign: "center", margin: "1vh", fontSize: "1.5rem"}}>
          NUEVA COLECCIÓN
        </h2>
      </a>
      <div className="center">
        
        {/* </Link> */}
        <div id="">
          <div id="filaIndex" className="" style={{ padding: "1vw", paddingTop: "0px" }}>
            {categorias.map((categoria, index) => (
              <a
                key={index}
                href={"/Mercado/" + categoria[8] + "/" + categoria[7]}
                onClick={() =>{}
                  // CambioProductos(
                  //   categoria[2],
                  //   categoria[3],
                  //   categoria[4],
                  //   categoria[5],
                  //   categoria[6],
                  //   categoria[9]
                  // )
                }
              >
                <div className="contenedorCarrito">
                  <picture>
                    <source srcSet={categoria[1]} media="(min-width: 1400px)" />
                    {/* 2000x500 */}
                    <source srcSet={categoria[1]} media="(min-width: 769px)" />
                    {/* 1400x500 */}
                    <source srcSet={categoria[1]} media="(min-width: 577px)" />
                    {/* 800x600 */}
                    <img
                      srcSet={categoria[1]}
                      alt="responsive image"
                      className="d-block img-fluid"
                    />
                    {/* 600x500 */}
                  </picture>

                  {/* <h2
                    id="Boton"
                    className="FuenteBebas centradoBotonIndex"
                    style={{ textAlign: "center" }}
                  >
                    {categoria[0]}
                  </h2> */}
                </div>
              </a>
            ))}
          </div>
        </div>
        {/* <div>
          <EpaycoButton />
        </div>  */}
        <Footer></Footer>
      </div>
    </>
  );
};

export default NewIndex;
